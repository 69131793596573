<template>
  <form
    @submit.prevent="save"
    class="justify-items-center flex flex-col relative"
  >
    <div>
      <div class="mx-4 p-4 text-center">
        <span class="text-3xl text-white">{{ $t("New transfer") }}</span>
      </div>
    </div>
    <div class="flex flex-col">
      <span class="text-gray-700">{{ $t("Date") }}</span>
      <div class="flex">
        <datepicker
          class="
            modal-cs-width
            text-secondary
            bg-white
            rounded-md
            border border-gray-400
            hover:border-gray-500
            px-4
            py-2
            pr-8
            shadow
            leading-tight
            focus:outline-none focus:shadow-outline
          "
          :placeholder="'Month'"
          :inputFormat="'MMM'"
          :minimumView="'month'"
          :maximumView="'month'"
          :initialView="'month'"
          :locale="lan"
          v-model="month"
        ></datepicker>
        <datepicker
          class="
            modal-cs-width
            text-secondary
            bg-white
            rounded-md
            border border-gray-400
            hover:border-gray-500
            px-4
            py-2
            pr-8
            shadow
            leading-tight
            ml-9999
          "
          :placeholder="'Year'"
          :inputFormat="'yyyy'"
          :minimumView="'year'"
          :maximumView="'year'"
          :initialView="'year'"
          v-model="year"
        ></datepicker>
      </div>
    </div>
    <div class="mt-5">
      <div>
        <label for="name" class="block text-sm font-medium text-gray-700">
          {{ $t("Repetition") }}
        </label>
        <div class="flex flex-row">
          <div
            class="flex items-center w-full mr-4"
            v-for="item in repetitions"
            :key="item.value"
          >
            <input
              :id="`repetition${item.value}`"
              name="budget"
              v-model="info.repetition"
              :value="item.value"
              required
              type="radio"
              class="
                focus:ring-fuchsia-500
                h-4
                w-4
                text-secondary
                border-gray-300
              "
            />
            <label :for="`repetition${item.value}`" class="ml-1">
              <span class="block text-sm text-gray-700">{{
                $t(item.name)
              }}</span>
            </label>
          </div>
        </div>
      </div>
      <div
        class="space-y-4"
        v-if="info.repetition == 'm' || info.repetition == 'y'"
      >
        <div>
          <label
            for="repetition_f"
            class="block text-sm font-medium text-gray-700"
            >{{ $t("every") }}
            <small>
              ({{ $t("Number of repetitions per month or year") }})</small
            ></label
          >
          <select
            required
            id="repetition_f"
            name="repetition_f"
            v-model="info.repetition_f"
            class="
              w-full
              my-2
              p-1
              bg-white
              border border-gray-400
              hover:border-gray-500
              px-4
              py-2
              pr-8
              rounded
              shadow
              leading-tight
              focus:outline-none focus:shadow-outline
            "
          >
            <option v-for="number in 36" :key="number" :value="number">
              {{ number }}
            </option>
          </select>
        </div>
        <div>
          <label
            for="repetition_n "
            class="block text-sm font-medium text-gray-700"
            >{{ $t("Number") }}</label
          >
          <select
            required
            id="repetition_n "
            name="repetition_n "
            v-model="info.repetition_n"
            class="
              w-full
              my-2
              p-1
              bg-white
              border border-gray-400
              hover:border-gray-500
              px-4
              py-2
              pr-8
              rounded
              shadow
              leading-tight
              focus:outline-none focus:shadow-outline
            "
          >
            <option
              v-for="item in repetition_ns"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="flex space-x-2 mt-5">
      <div class="input">
        <div class="block relative w-full my-2">
          <span class="text-sm text-gray-700">{{ $t("From account") }}</span>
          <select
            required
            v-model="info.source_account_id"
            class="
              w-full
              my-2
              p-1
              bg-white
              border border-gray-400
              hover:border-gray-500
              px-4
              py-2
              pr-8
              rounded
              shadow
              leading-tight
              focus:outline-none focus:shadow-outline
            "
          >
            <option
              v-for="(item, i) in accountList"
              :key="i"
              :value="item.id"
              :disabled="item.id == info.destination_account_id"
              :class="
                item.id == info.destination_account_id ? 'bg-gray-300' : ''
              "
            >
              {{ $t(item.name) }}
            </option>
          </select>
        </div>
      </div>
      <div class="input">
        <div class="block relative w-full my-2">
          <span class="text-sm text-gray-700">{{ $t("To account") }}</span>
          <select
            required
            v-model="info.destination_account_id"
            class="
              w-full
              my-2
              p-1
              bg-white
              border border-gray-400
              hover:border-gray-500
              px-4
              py-2
              pr-8
              rounded
              shadow
              leading-tight
              focus:outline-none focus:shadow-outline
            "
          >
            <option
              v-for="(item, i) in accountList"
              :key="i"
              :value="item.id"
              :disabled="item.id == info.source_account_id"
              :class="
                item.id == info.source_account_id ? 'cursor-not-allowed' : ''
              "
              class="bg-red-500"
            >
              {{ $t(item.name) }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div>
      <label class="text-gray-700">{{ $t("Amount") }}</label>
      <div class="input">
        <input
          id="amount"
          ref="amount"
          type="text"
          name="betrag"
          tabindex="30"
          required
          v-model="amount"
          @change="convertEntry"
          placeholder="0"
          class="
            w-full
            my-2
            p-1
            bg-white
            border border-gray-400
            hover:border-gray-500
            px-4
            py-2
            pr-8
            rounded
            shadow
            leading-tight
            focus:outline-none focus:shadow-outline
          "
        />
      </div>
    </div>
    <div>
      <div class="input">
        <span class="textmd">{{ $t("comment") }}</span>
        <textarea
          v-model="info.comment"
          class="
            block
            relative
            my-2
            p-1
            appearance-none
            w-full
            bg-white
            border border-gray-400
            hover:border-gray-500
            px-4
            py-2
            pr-8
            rounded
            shadow
            leading-tight
            focus:outline-none focus:shadow-outline
          "
          rows="10"
        ></textarea>
      </div>
    </div>
    <div>
      <button
        type="submit"
        class="
          w-full
          text-white
          flex
          justify-center
          py-2
          px-4
          border border-transparent
          rounded-md
          shadow-sm
          text-sm
          font-medium
          bg-secondary
          hover:bg-secondary hover:text-white
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-secondary
        "
      >
        {{ $t("Save") }}
      </button>
    </div>
    <div class="mt-2">
      <button
        @click.prevent="deletePlan()"
        class="
          w-full
          text-white
          flex
          justify-center
          py-2
          px-4
          rounded-md
          shadow-sm
          font-medium
          bg-red-500
        "
      >
        <TrashIcon class="h-6 w-6" />
      </button>
    </div>
    <button @click="$emit('close')" class="absolute top-0 right-0">
      <XIcon class="h-5 w-5 text-white" />
    </button>
  </form>
</template>

<script>
import Datepicker from "../../node_modules/vue3-datepicker";
import { de, it } from "date-fns/locale";
import { XIcon, TrashIcon } from "@heroicons/vue/outline";

const repetitions = [
  { name: "no", value: "0" },
  { name: "monthly", value: "m" },
  { name: "yearly", value: "y" },
];
let repetition_ns = [{ id: 0, name: "unendlich" }];
for (let i = 1; i < 36; i++) {
  repetition_ns.push({ id: i, name: i });
}
export default {
  props: ["data", "mode"],
  data() {
    return {
      date: null,
      repetitions,
      amount: "",
      info: {
        date: "",
        repetition: "0",
        source_account_id: "",
        destination_account_id: "",
      },
      repetition_ns,
      month: new Date(),
      year: new Date(),
      lan: this.$i18n.locale == "de" ? de : it,
      id: "",
    };
  },
  components: {
    Datepicker,
    XIcon,
    TrashIcon,
  },
  computed: {
    accountList() {
      return this.$store.state.accounts.accountList.data || [];
    },
  },
  watch: {
    month() {
      let month = (this.month.getMonth() + 1).toString().padStart(2, "0");
      this.info.date = `${this.year.getFullYear()}-${month}-01`;
      this.$store.commit("setMainDate", this.year);
    },
    year() {
      let month = (this.month.getMonth() + 1).toString().padStart(2, "0");
      this.info.date = `${this.year.getFullYear()}-${month}-01`;
      this.$store.commit("setMainDate", this.month);
    },
  },
  methods: {
    save() {
      if (this.info.date == "") {
        this.info.date = `${new Date().getFullYear()}-${
          new Date().getMonth() + 1
        }-01`;
      }
      if (this.mode == "new") {
        this.$store
          .dispatch("transfer/registerTransfer", this.info)
          .then((res) => {
            if (res.type == "success") this.$emit("close");
          });
        return;
      }
      this.$store
        .dispatch("transfer/updateTransfer", { info: this.info })
        .then((res) => {
          if (res.type == "success") this.$emit("close");
        });
    },
    focusInput() {
      this.$refs.amount.focus();
    },
    convertEntry() {
      if (this.amount == "") {
        this.amount = this.data.amount.toString();
      }
      String.prototype.replaceAllLocal = function (search, replacement) {
        var target = this;
        return target.split(search).join(replacement);
      };
      this.amount = this.amount.replaceAllLocal(".", "");
      this.amount = this.amount.replaceAllLocal(",", ".");
      this.amount = parseFloat(this.amount).toLocaleString("de-DE");
      this.info.amount = this.amount.replaceAllLocal(".", "");
      this.info.amount = this.info.amount.replaceAllLocal(",", ".");
      this.info.amount = Math.abs(this.info.amount);
    },
    deletePlan() {
      this.$store
        .dispatch("transfer/deleteTransfer", { id: this.id })
        .then(() => {
          this.$emit("close");
        });
    },
  },
  mounted() {
    // console.log("transferModal");
    this.month = this.$store.state.mainDate;
    this.year = this.$store.state.mainDate;
    this.focusInput();
    if (this.mode == "update") {
      this.id = this.data.id;
      this.info.id = this.data.id;
      this.info.repetition = this.data.repetition;
      this.info.repetition_f = this.data.repetition_f;
      this.info.repetition_n = this.data.repetition_n;
      this.info.comment = this.data.comment;
      // this.amount = this.data.amount;
      this.info.amount = this.data.amount;
      this.month = new Date(this.data.date);
      this.year = new Date(this.data.date);
      this.info.source_account_id = this.data.source_account
        ? this.data.source_account.id
        : null;
      this.info.destination_account_id = this.data.destination_account
        ? this.data.destination_account.id
        : null;

      this.amount = parseFloat(this.data.amount).toLocaleString("de-DE");
    }
  },
};
</script>

<style scoped>
optgroup {
  background-color: #c026d3;
  color: #fff;
}
option {
  background-color: #fff;
  color: #47515f !important;
}
</style>
