<template>
  <form @submit.prevent="save">
    <div>
      <div class="mx-4 p-4 text-center">
        <span class="text-3xl text-white">{{ $t("New Account") }}</span>
      </div>
    </div>

    <div class="input">
      <label>{{ $t("name") }}</label>
      <div class="input">
        <input
          type="text"
          name="betrag"
          tabindex="30"
          required
          v-model="info.name"
          class="
            w-full
            my-2
            p-1
            bg-white
            border border-gray-400
            hover:border-gray-500
            px-4
            py-2
            pr-8
            rounded
            shadow
            leading-tight
            focus:outline-none focus:shadow-outline
          "
        />
      </div>
      <label class="capitalize">{{ $t("starting value") }}</label>
      <div class="input">
        <input
          type="text"
          name="credit"
          tabindex="30"
          required
          v-model="amount"
          @change="convertEntry"
          class="
            w-full
            my-2
            p-1
            bg-white
            border border-gray-400
            hover:border-gray-500
            px-4
            py-2
            pr-8
            rounded
            shadow
            leading-tight
            focus:outline-none focus:shadow-outline
          "
        />
      </div>
      <div class="mt-4">
        <button
          type="submit"
          class="
            w-full
            text-secondary
            flex
            justify-center
            py-2
            px-4
            border border-transparent
            rounded-md
            shadow-sm
            text-sm
            font-medium
            bg-white
            hover:bg-secondary hover:text-white
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-secondary
          "
        >
          {{ $t("Save") }}
        </button>
      </div>
      <div class="mt-4" v-if="mode == 'update' && !data.is_static">
        <button
          @click.prevent="DeleteAccount()"
          type="submit"
          class="
            w-full
            flex
            justify-center
            py-2
            px-4
            border border-transparent
            rounded-md
            shadow-sm
            text-sm
            font-medium
            bg-red-500
          "
        >
          <TrashIcon class="h-6 w-6 text-white" />
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { TrashIcon } from "@heroicons/vue/outline";
export default {
  props: ["data", "mode"],
  components: { TrashIcon },
  data() {
    return {
      date: null,
      info: {},
      amount: "",
    };
  },
  methods: {
    save() {
      if (this.mode == "new") {
        this.$store
          .dispatch("accounts/RegisterAccount", this.info)
          .then((res) => {
            if (res.type == "success") this.$emit("close");
          });
        return;
      }
      this.$store.dispatch("accounts/UpdateAccount", this.info).then((res) => {
        if (res.type == "success") this.$emit("close");
      });
    },
    DeleteAccount() {
      this.$store
        .dispatch("accounts/DeleteAccount", this.info.id)
        .then((res) => {
          if (res.type == "success") this.$emit("close");
        });
    },
    convertEntry() {
      if (this.amount == "") {
        this.amount = this.data.amount.toString();
      }
      String.prototype.replaceAllLocal = function (search, replacement) {
        var target = this;
        return target.split(search).join(replacement);
      };
      this.amount = this.amount.replaceAllLocal(".", "");
      this.amount = this.amount.replaceAllLocal(",", ".");
      this.amount = parseFloat(this.amount).toLocaleString("de-DE");
      this.info.credit = this.amount.replaceAllLocal(".", "");
      this.info.credit = this.info.credit.replaceAllLocal(",", ".");
    },
  },
  mounted() {
    if (this.mode == "update") {
      this.info = this.data;
      this.info.name = this.$t(this.data.name);
      this.amount = parseFloat(this.data.credit).toLocaleString("de-DE");
      // console.log(this.data.credit);
    }
  },
};
</script>
