<template>
  <form class="space-y-6" @submit.prevent="save">
    <div>
      <label for="name"> {{ $t("Name") }} </label>
      <div class="mt-1">
        <input
          id="name"
          name="name"
          type="text"
          required
          v-model="name"
          class="
            w-full
            my-2
            p-1
            bg-white
            border border-gray-400
            hover:border-gray-500
            px-4
            py-2
            pr-8
            rounded
            shadow
            leading-tight
            focus:outline-none focus:shadow-outline
          "
        />
      </div>
    </div>
    <div>
      <button
        type="submit"
        class="
          w-full
          text-secondary
          flex
          justify-center
          py-2
          px-4
          border border-transparent
          rounded-md
          shadow-sm
          text-sm
          font-medium
          bg-white
          hover:bg-secondary hover:text-white
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-secondary
        "
      >
        {{ $t("Save") }}
      </button>
    </div>
  </form>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      name: null,
    };
  },
  methods: {
    save() {
      if (this.data) {
        this.$store
          .dispatch("User/UpdatePersons", {
            id: this.data.id,
            name: this.name,
          })
          .then((res) => {
            if (res.type == "success") this.$emit("close");
          });
        return;
      }
      this.$store
        .dispatch("User/RegisterPersons", { name: this.name })
        .then((res) => {
          if (res.type == "success") this.$emit("close");
        });
    },
  },
  mounted() {
    if (this.data) this.name = this.data.name;
  },
};
</script>

<style></style>
