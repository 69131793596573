<template>
  <form class="space-y-6" @submit.prevent="save">
    <div>
      <div class="flex flex-col">
        <span class="text-gray-700">{{ $t("Date") }}</span>
        <div class="flex">
          <datepicker
            class="
              modal-cs-width
              text-secondary
              bg-white
              rounded-md
              border border-gray-400
              hover:border-gray-500
              px-4
              py-2
              pr-8
              shadow
              leading-tight
              focus:outline-none focus:shadow-outline
            "
            :inputFormat="'dd-MM-yyyy'"
            v-model="date"
            :locale="lan"
          ></datepicker>
        </div>
      </div>
    </div>
    <div>
      <button
        id="submit"
        ref="submit"
        type="submit"
        class="
          w-full
          text-secondary
          flex
          justify-center
          py-2
          px-4
          border border-transparent
          rounded-md
          shadow-sm
          text-sm
          font-medium
          bg-white
          hover:bg-secondary hover:text-white
        "
      >
        {{ $t("Save") }}
      </button>
    </div>
  </form>
</template>

<script>
import Datepicker from "../../node_modules/vue3-datepicker";
import { de, it } from "date-fns/locale";

export default {
  props: ["data", "mode"],
  data() {
    return {
      date: new Date(),
      lan: this.$i18n.locale == "de" ? de : it,
    };
  },
  components: { Datepicker },

  methods: {
    save() {
      const currentDate = {
        from: `${this.date.getFullYear()}-${this.date.getMonth() + 1}-1`,
        to: `${this.date.getFullYear()}-${
          this.date.getMonth() + 1
        }-${this.endMonthDay()}`,
      };
      const ids =
        typeof this.data == Object
          ? Object.values(this.data).join(",")
          : this.data;
      if (this.mode == "planningMove") {
        this.$store
          .dispatch("planning/setEffetivePaln", {
            date: `${this.date.getFullYear()}-${
              this.date.getMonth() + 1
            }-${this.date.getDate()}`,
            id: ids,
            currentDate: currentDate,
          })
          .then((res) => {
            this.emitter.emit("showNotif", {
              type: res.type,
              msg: res.msg,
              showNotif: true,
            });
            if (res.type == "success") this.$emit("close");
          });
      }
      if (this.mode == "transferEffective") {
        this.$store
          .dispatch("transfer/doEffectTransferList", {
            date: `${this.date.getFullYear()}-${
              this.date.getMonth() + 1
            }-${this.date.getDate()}`,
            id: ids,
            currentDate: currentDate,
          })
          .then((res) => {
            this.emitter.emit("showNotif", {
              type: res.type,
              msg: res.msg,
              showNotif: true,
            });
            if (res.type == "success") this.$emit("close");
          });
      }
    },
    endMonthDay() {
      const endDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth() + 1,
        0
      );
      return endDate.getDate();
    },
  },
  mounted() {
    // console.log("effective modal");
    this.date = this.$store.state.mainDate;
    this.$refs.submit.focus();
  },
};
</script>

<style></style>
