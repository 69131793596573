<template>
  <form class="space-y-6" @submit.prevent="save">
    <div>
      <label for="parent_id" class="block text-sm font-medium text-gray-700">{{
        $t("Area")
      }}</label>
      <select
        id="parent_id"
        name="parent_id"
        v-model="parent_id"
        class="
          mt-1
          block
          w-full
          pl-3
          pr-10
          py-2
          text-base
          border-gray-300
          focus:outline-none focus:ring-fuchsia-500 focus:border-fuchsia-500
          sm:text-sm
          rounded-md
        "
      >
        <optgroup v-for="(cat, i) in categories" :key="i" :label="$t(cat.name)">
          <option v-for="(item, i) in cat.children" :key="i" :value="item.id">
            {{ $t(item.name) }}
          </option>
        </optgroup>
      </select>
    </div>
    <div>
      <label for="name" class="block text-sm font-medium text-gray-700">
        {{ $t("Name") }}
      </label>
      <div class="mt-1">
        <input
          id="name"
          name="name"
          type="text"
          required
          v-model="name"
          class="
            appearance-none
            block
            w-full
            px-3
            py-2
            border border-gray-300
            rounded-md
            shadow-sm
            placeholder-gray-400
            focus:outline-none focus:ring-fuchsia-500 focus:border-fuchsia-500
            sm:text-sm
          "
        />
      </div>
    </div>
    <div>
      <button
        type="submit"
        class="
          w-full
          flex
          justify-center
          py-2
          px-4
          border border-transparent
          rounded-md
          shadow-sm
          text-sm
          font-medium
          text-white
          bg-fuchsia-600
          hover:bg-fuchsia-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-fuchsia-500
        "
      >
        {{ $t("Save") }}
      </button>
    </div>
  </form>
</template>

<script>
export default {
  props: ["parentId", "data", "mode"],
  data() {
    return {
      name: null,
      parent_id: null,
    };
  },
  computed: {
    categories() {
      return this.$store.state.User.categories;
    },
  },
  methods: {
    UpdateCategory(id) {
      this.$store.dispatch("User/UpdateCategory", id);
    },
    save() {
      if (this.mode === "new") {
        this.$store
          .dispatch("User/RegisterCategory", {
            name: this.name,
            parent_id: this.parent_id,
            region_id: this.region_id,
          })
          .then(() => {
            this.$emit("close");
          });
      } else {
        this.$store
          .dispatch("User/UpdateCategory", {
            name: this.name,
            parent_id: this.parent_id,
            id: this.data.id,
          })
          .then(() => {
            this.$emit("close");
          });
      }
    },
  },
  mounted() {
    this.parent_id = this.parentId;
    if (this.mode == "update") {
      this.name = this.data.name;
    }
  },
};
</script>

<style></style>
