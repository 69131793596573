<template>
  <form
    @submit.prevent="save"
    class="justify-items-center flex flex-col relative"
  >
    <div>
      <div class="mx-4 p-4 text-center">
        <span
          class="text-2xl text-white"
          :class="mode == 'new' ? 'text-3xl' : 'text-2xl'"
          >{{
            mode == "new" ? $t("New transfer") : $t("Update transfer")
          }}</span
        >
      </div>
    </div>
    <div class="flex flex-col">
      <span class="text-gray-700">{{ $t("Date") }}</span>
      <div class="flex">
        <datepicker
          class="
            modal-cs-width
            text-secondary
            bg-white
            rounded-md
            border border-gray-400
            hover:border-gray-500
            px-4
            py-2
            pr-8
            shadow
            leading-tight
            focus:outline-none focus:shadow-outline
          "
          :inputFormat="'dd-MM-yyyy'"
          v-model="date"
          :locale="lan"
        ></datepicker>
      </div>
    </div>
    <div class="flex space-x-2 mt-5">
      <div class="input">
        <div class="block relative w-full my-2">
          <span class="text-sm text-gray-700">{{ $t("From account") }}</span>
          <select
            required
            v-model="info.source_account_id"
            class="
              w-full
              my-2
              p-1
              bg-white
              border border-gray-400
              hover:border-gray-500
              px-4
              py-2
              pr-8
              rounded
              shadow
              leading-tight
              focus:outline-none focus:shadow-outline
            "
          >
            <option
              v-for="(item, i) in accountList"
              :key="i"
              :value="item.id"
              :disabled="item.id == info.destination_account_id"
            >
              {{ $t(item.name) }}
            </option>
          </select>
        </div>
      </div>
      <div class="input">
        <div class="block relative w-full my-2">
          <span class="text-sm text-gray-700">{{ $t("To account") }}</span>
          <select
            required
            v-model="info.destination_account_id"
            class="
              w-full
              my-2
              p-1
              bg-white
              border border-gray-400
              hover:border-gray-500
              px-4
              py-2
              pr-8
              rounded
              shadow
              leading-tight
              focus:outline-none focus:shadow-outline
            "
          >
            <option
              v-for="(item, i) in accountList"
              :key="i"
              :value="item.id"
              :disabled="item.id == info.source_account_id"
            >
              {{ $t(item.name) }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div>
      <label class="text-gray-700">{{ $t("Amount") }}</label>
      <div class="input">
        <input
          id="amount"
          ref="amount"
          type="text"
          name="betrag"
          tabindex="30"
          required
          v-model="amount"
          @change="convertEntry"
          placeholder="0"
          class="
            w-full
            my-2
            p-1
            bg-white
            border border-gray-400
            hover:border-gray-500
            px-4
            py-2
            pr-8
            rounded
            shadow
            leading-tight
            focus:outline-none focus:shadow-outline
          "
        />
      </div>
    </div>
    <div>
      <div class="input">
        <span class="textmd">{{ $t("comment") }}</span>
        <textarea
          v-model="info.comment"
          class="
            block
            relative
            my-2
            p-1
            appearance-none
            w-full
            bg-white
            border border-gray-400
            hover:border-gray-500
            px-4
            py-2
            pr-8
            rounded
            shadow
            leading-tight
            focus:outline-none focus:shadow-outline
          "
          rows="10"
        ></textarea>
      </div>
    </div>
    <div>
      <button
        type="submit"
        class="
          w-full
          text-white
          flex
          justify-center
          py-2
          px-4
          border border-transparent
          rounded-md
          shadow-sm
          text-sm
          font-medium
          bg-secondary
          hover:bg-secondary hover:text-white
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-secondary
        "
      >
        {{ $t("Save") }}
      </button>
    </div>
    <div v-if="mode == 'update'">
      <button
        @click.prevent="deleteMovement()"
        class="
          mt-5
          w-full
          bg-red-500
          flex
          justify-center
          py-2
          px-4
          border border-transparent
          rounded-md
          shadow-sm
          text-sm
          font-medium
        "
      >
        <TrashIcon class="h-6 w-6 text-white" />
      </button>
    </div>
    <button @click="$emit('close')" class="absolute top-0 right-0">
      <XIcon class="h-5 w-5 text-white" />
    </button>
  </form>
</template>

<script>
import Datepicker from "../../node_modules/vue3-datepicker";
import { de, it } from "date-fns/locale";
import { XIcon, TrashIcon } from "@heroicons/vue/outline";

export default {
  props: ["data", "mode"],
  data() {
    return {
      amount: "",
      info: {
        date: "",
        source_account_id: "",
        destination_account_id: "",
      },
      lan: this.$i18n.locale == "de" ? de : it,
      date: new Date(),
      id: "",
    };
  },
  components: {
    Datepicker,
    XIcon,
    TrashIcon,
  },
  computed: {
    accountList() {
      return this.$store.state.accounts.accountList.data || [];
    },
  },
  watch: {
    date() {
      this.info.date = `${this.date.getFullYear()}-${
        this.date.getMonth() + 1
      }-${this.date.getDate()}`;
    },
  },
  methods: {
    save() {
      if (this.info.date == "") {
        this.info.date = `${new Date().getFullYear()}-${
          new Date().getMonth() + 1
        }-01`;
      }
      if (this.mode == "new") {
        this.$store
          .dispatch("transfer/registerEffectiveTransfer", this.info)
          .then((res) => {
            if (res.type == "success") this.$emit("close");
          });
        return;
      }
      delete this.info.category;
      delete this.info.clear_account;
      delete this.info.parent;
      this.$store
        .dispatch("transfer/updateEffectiveTransfer", { info: this.info })
        .then((res) => {
          if (res.type == "success") this.$emit("close");
        });
    },
    focusInput() {
      this.$refs.amount.focus();
    },
    convertEntry() {
      if (this.amount == "") {
        this.amount = this.data.amount.toString();
      }
      String.prototype.replaceAllLocal = function (search, replacement) {
        var target = this;
        return target.split(search).join(replacement);
      };
      if (this.amount.includes(",")) {
        this.amount = this.amount.replaceAllLocal(",", ".");
      }
      this.amount = parseFloat(this.amount.toString()).toLocaleString("de-DE");
      this.info.amount = this.amount.replaceAllLocal(".", "");
      this.info.amount = this.info.amount.replaceAllLocal(",", ".");
      this.info.amount = Math.abs(this.info.amount);
    },
    deleteMovement() {
      this.$emit("close");
      this.$store
        .dispatch("transfer/deleteEffectiveTransfer", this.id)
        .then(() => {
          this.$emit("fetchData");
        });
    },
  },
  mounted() {
    // console.log("movementtransfermodal");
    this.date = this.$store.state.mainDate;
    this.focusInput();
    if (this.mode == "update") {
      this.id = this.data.id;
      this.info.comment = this.data.comment;
      // this.amount = this.data.amount;
      this.info.amount = this.data.amount;
      this.date = new Date(this.data.date);
      this.info.source_account_id = this.data.source_account.id;
      this.info.destination_account_id = this.data.destination_account.id;
      this.info.id = this.data.id;

      if (this.data.amount.toString().includes(".")) {
        this.amount = this.data.amount.toString().replace(".", ",");
      } else this.amount = this.data.amount;

      // console.log(this.id);
    }
  },
};
</script>

<style scoped>
optgroup {
  background-color: #c026d3;
  color: #fff;
}
option {
  background-color: #fff;
  color: #47515f !important;
}
</style>
