<template>
  <form class="space-y-6" @submit.prevent="save">
    <div class="flex flex-col">
      <span class="text-gray-700">{{ $t("Date") }}</span>
      <div class="flex">
        <datepicker
          class="
            modal-cs-width
            text-secondary
            bg-white
            rounded-md
            border border-gray-400
            hover:border-gray-500
            px-4
            py-2
            pr-8
            shadow
            leading-tight
            focus:outline-none focus:shadow-outline
          "
          :inputFormat="'dd-MM-yyyy'"
          v-model="date"
          :locale="lan"
        ></datepicker>
      </div>
    </div>
    <div>
      <label class="text-gray-700">{{ $t("Amount") }}</label>
      <div class="input">
        <input
          id="amount"
          ref="amount"
          type="text"
          name="betrag"
          tabindex="30"
          required
          v-model="amount"
          @change="convertEntry"
          placeholder="0"
          class="
            w-full
            my-2
            p-1
            bg-white
            border border-gray-400
            hover:border-gray-500
            px-4
            py-2
            pr-8
            rounded
            shadow
            leading-tight
            focus:outline-none focus:shadow-outline
          "
        />
      </div>
    </div>
    <div v-if="showAccount" class="input">
      <div class="block relative w-full my-2">
        <span class="text-md">{{ $t("Account") }}</span>
        <select
          v-model="moveData.account_id"
          class="
            w-full
            my-2
            p-1
            bg-white
            border border-gray-400
            hover:border-gray-500
            px-4
            py-2
            pr-8
            rounded
            shadow
            leading-tight
            focus:outline-none focus:shadow-outline
          "
        >
          <option v-for="(item, i) in accountList" :key="i" :value="item.id">
            {{ $t(item.name) }}
          </option>
        </select>
      </div>
    </div>
    <div>
      <button
        type="submit"
        class="
          w-full
          text-secondary
          flex
          justify-center
          py-2
          px-4
          border border-transparent
          rounded-md
          shadow-sm
          text-sm
          font-medium
          bg-white
          hover:bg-secondary hover:text-white
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-secondary
        "
      >
        {{ $t("Save") }}
      </button>
    </div>
  </form>
</template>

<script>
import Datepicker from "../../node_modules/vue3-datepicker";
import { de, it } from "date-fns/locale";

export default {
  props: ["data", "mode"],
  data() {
    return {
      date: new Date(),
      moveData: {},
      amount: "",
      showAccount: true,
      lan: this.$i18n.locale == "de" ? de : it,
    };
  },
  components: { Datepicker },
  computed: {
    accountList() {
      return this.$store.state.accounts.accountList.data || [];
    },
  },
  methods: {
    save() {
      const currentDate = {
        from: `${this.date.getFullYear()}-${this.date.getMonth() + 1}-01`,
        to: `${this.date.getFullYear()}-${
          this.date.getMonth() + 1
        }-${this.endMonthDay()}`,
      };

      if (this.mode == "planningMove") {
        this.$store
          .dispatch("planning/setEffetivePaln", {
            date: `${this.date.getFullYear()}-${
              this.date.getMonth() + 1
            }-${this.date.getDate()}`,
            id: this.moveData.id,
            amount: this.moveData.amount,
            currentDate: currentDate,
            account_id: this.moveData.account_id,
          })
          .then((res) => {
            this.emitter.emit("showNotif", {
              type: res.type,
              msg: res.msg,
              showNotif: true,
            });
            if (res.type == "success") this.$emit("close");
          });
      }
      if (this.mode == "transferEffective") {
        this.$store
          .dispatch("transfer/doEffectTransferList", {
            date: `${this.date.getFullYear()}-${
              this.date.getMonth() + 1
            }-${this.date.getDate()}`,
            id: this.moveData.id,
            amount: this.moveData.amount,
            currentDate: currentDate,
          })
          .then((res) => {
            this.emitter.emit("showNotif", {
              type: res.type,
              msg: res.msg,
              showNotif: true,
            });
            if (res.type == "success") this.$emit("close");
          });
      }
    },
    endMonthDay() {
      const endDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth() + 1,
        0
      );
      return endDate.getDate();
    },
    convertEntry() {
      if (this.amount == "") {
        this.amount = this.data.amount.toString();
      }
      String.prototype.replaceAllLocal = function (search, replacement) {
        var target = this;
        return target.split(search).join(replacement);
      };
      this.amount = this.amount.replaceAllLocal(".", "");
      this.amount = this.amount.replaceAllLocal(",", ".");
      this.amount = parseFloat(this.amount).toLocaleString("de-DE");
      this.moveData.amount = this.amount.replaceAllLocal(".", "");
      this.moveData.amount = this.moveData.amount.replaceAllLocal(",", ".");
      this.moveData.amount = Math.abs(this.moveData.amount);
    },
  },
  mounted() {
    // console.log("singleEffectiveModal");
    this.date = this.$store.state.mainDate;
    this.$refs.amount.focus();
    this.moveData.amount = this.data.amount;
    this.moveData.account_id = this.data.account ? this.data.account.id : null;
    this.moveData.id = this.data.id;
    if (this.data.category && this.data.category.category_type_id == 4) {
      this.showAccount = false;
    }
    this.amount = parseFloat(this.data.amount).toLocaleString("de-DE");

    // if (this.data.amount.toString().includes(".")) {
    //   this.amount = this.data.amount.toString().replace(".", ",");
    // } else this.amount = this.data.amount;
  },
};
</script>

<style></style>
