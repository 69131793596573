<template>
  <form class="relative" @submit.prevent="save">
    <div>
      <div class="mx-4 p-4 text-center">
        <span class="text-3xl text-white">{{
          mode == "update" ? $t("Update Planning") : $t("New Planning")
        }}</span>
      </div>
    </div>

    <div class="input">
      <div class="block relative w-full my-2">
        <select
          placeholder="Pick a state..."
          v-model="selectedCat"
          class="
            w-full
            my-2
            p-1
            bg-white
            border border-gray-400
            hover:border-gray-500
            px-4
            py-2
            pr-8
            rounded
            shadow
            leading-tight
            focus:outline-none focus:shadow-outline
          "
          required
        >
          <option
            v-for="tab in categoriesTitles"
            :key="tab.name"
            :value="tab.id"
            :selected="categoriesTitles[0].id"
          >
            {{ $t(tab.name) }}
          </option>
        </select>
      </div>
      <div class="block relative w-full my-2">
        <select
          id="select-state"
          placeholder="Pick a state..."
          v-model="info.category_id"
          class="
            w-full
            my-2
            p-1
            bg-white
            border border-gray-400
            hover:border-gray-500
            px-4
            py-2
            pr-8
            rounded
            shadow
            leading-tight
            focus:outline-none focus:shadow-outline
          "
          required
        >
          <optgroup
            v-for="(cat, i) in categories"
            :key="i"
            :label="$t(cat.name)"
          >
            <option
              v-for="(item, i) in cat.children.filter((cat) => cat.active)"
              :key="i"
              :value="item.id"
            >
              {{ $t(item.name) }}
            </option>
          </optgroup>
        </select>
      </div>
      <div>
        <label for="name" class="block text-sm font-medium text-gray-700">
          {{ $t("Repetition") }}
        </label>
        <div class="flex flex-row">
          <div
            class="flex items-center w-full mr-4"
            v-for="item in repetitions"
            :key="item.value"
          >
            <input
              :id="`repetition${item.value}`"
              name="budget"
              v-model="info.repetition"
              :value="item.value"
              required
              type="radio"
              class="
                focus:ring-fuchsia-500
                h-4
                w-4
                text-secondary
                border-gray-300
              "
            />
            <label :for="`repetition${item.value}`" class="ml-1">
              <span class="block text-sm text-gray-700">{{
                $t(item.name)
              }}</span>
            </label>
          </div>
        </div>
      </div>
      <div
        class="space-y-4"
        v-if="info.repetition == 'm' || info.repetition == 'y'"
      >
        <div>
          <label
            for="repetition_f"
            class="block text-sm font-medium text-gray-700"
            >{{ $t("every") }}
            <small>
              ({{ $t("Number of repetitions per month or year") }})</small
            ></label
          >
          <select
            id="repetition_f"
            name="repetition_f"
            v-model="info.repetition_f"
            class="
              w-full
              my-2
              p-1
              bg-white
              border border-gray-400
              hover:border-gray-500
              px-4
              py-2
              pr-8
              rounded
              shadow
              leading-tight
              focus:outline-none focus:shadow-outline
            "
          >
            <option v-for="number in 36" :key="number" :value="number">
              {{ number }}
            </option>
          </select>
        </div>
        <div>
          <label
            for="repetition_n "
            class="block text-sm font-medium text-gray-700"
            >{{ $t("Number") }}</label
          >
          <select
            id="repetition_n "
            name="repetition_n "
            v-model="info.repetition_n"
            class="
              w-full
              my-2
              p-1
              bg-white
              border border-gray-400
              hover:border-gray-500
              px-4
              py-2
              pr-8
              rounded
              shadow
              leading-tight
              focus:outline-none focus:shadow-outline
            "
          >
            <option
              v-for="item in repetition_ns"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="flex flex-col">
        <span class="text-gray-700">{{ $t("Date") }}</span>
        <div class="flex">
          <datepicker
            class="
              modal-cs-width
              text-secondary
              bg-white
              rounded-md
              border border-gray-400
              hover:border-gray-500
              px-4
              py-2
              pr-8
              shadow
              leading-tight
              focus:outline-none focus:shadow-outline
            "
            :placeholder="'Month'"
            :inputFormat="'MMM'"
            :minimumView="'month'"
            :maximumView="'month'"
            :initialView="'month'"
            :locale="lan"
            v-model="month"
          ></datepicker>
          <datepicker
            class="
              modal-cs-width
              text-secondary
              bg-white
              rounded-md
              border border-gray-400
              hover:border-gray-500
              px-4
              py-2
              pr-8
              shadow
              leading-tight
              ml-9999
            "
            :placeholder="'Year'"
            :inputFormat="'yyyy'"
            :minimumView="'year'"
            :maximumView="'year'"
            :initialView="'year'"
            v-model="year"
            :locale="lan"
          ></datepicker>
        </div>
      </div>
      <label>{{ $t("Amount") }}</label>
      <div class="input">
        <input
          type="text"
          name="betrag"
          tabindex="30"
          required
          v-model="amount"
          @change="convertEntry"
          class="
            w-full
            my-2
            p-1
            bg-white
            border border-gray-400
            hover:border-gray-500
            px-4
            py-2
            pr-8
            rounded
            shadow
            leading-tight
            focus:outline-none focus:shadow-outline
          "
        />
      </div>

      <div class="input">
        <div class="block relative w-full my-2">
          <span class="text-md">{{ $t("Person") }}</span>
          <select
            v-model="info.person_id"
            class="
              w-full
              my-2
              p-1
              bg-white
              border border-gray-400
              hover:border-gray-500
              px-4
              py-2
              pr-8
              rounded
              shadow
              leading-tight
              focus:outline-none focus:shadow-outline
            "
          >
            <option v-for="(item, i) in persons" :key="i" :value="item.id">
              {{ item.name == "together" ? $t("together") : item.name }}
            </option>
          </select>
        </div>
      </div>

      <div v-if="showAccount" class="input">
        <div class="block relative w-full my-2">
          <span class="text-md">{{ $t("Account") }}</span>
          <select
            v-model="info.account_id"
            class="
              w-full
              my-2
              p-1
              bg-white
              border border-gray-400
              hover:border-gray-500
              px-4
              py-2
              pr-8
              rounded
              shadow
              leading-tight
              focus:outline-none focus:shadow-outline
            "
          >
            <option v-for="(item, i) in accountList" :key="i" :value="item.id">
              {{ $t(item.name) }}
            </option>
          </select>
        </div>
      </div>

      <div class="input">
        <span class="textmd">{{ $t("comment") }}</span>
        <textarea
          v-model="info.comment"
          class="
            block
            relative
            my-2
            p-1
            appearance-none
            w-full
            bg-white
            border border-gray-400
            hover:border-gray-500
            px-4
            py-2
            pr-8
            rounded
            shadow
            leading-tight
            focus:outline-none focus:shadow-outline
          "
          rows="10"
        ></textarea>
      </div>
      <div>
        <button
          type="submit"
          class="
            w-full
            text-secondary
            flex
            justify-center
            py-2
            px-4
            border border-transparent
            rounded-md
            shadow-sm
            text-sm
            font-medium
            bg-white
            hover:bg-secondary hover:text-white
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-secondary
          "
        >
          {{ $t("Save") }}
        </button>
      </div>

      <div>
        <button
          @click.prevent="deletePlan"
          class="
            mt-2
            w-full
            text-white
            flex
            justify-center
            py-2
            px-4
            border border-transparent
            rounded-md
            shadow-sm
            text-sm
            font-medium
            bg-red-500
          "
        >
          <TrashIcon class="h-6 w-6" />
        </button>
      </div>
    </div>
    <button @click="$emit('close')" class="absolute top-0 right-0">
      <XIcon class="h-5 w-5 text-white" />
    </button>
  </form>
</template>

<script>
import Datepicker from "../../node_modules/vue3-datepicker";
import { de, it } from "date-fns/locale";
import { XIcon, TrashIcon } from "@heroicons/vue/outline";

const repetitions = [
  { name: "no", value: "0" },
  { name: "monthly", value: "m" },
  { name: "yearly", value: "y" },
];
let repetition_ns = [{ id: 0, name: "unendlich" }];
for (let i = 1; i < 36; i++) {
  repetition_ns.push({ id: i, name: i });
}
export default {
  props: ["data", "mode"],
  data() {
    return {
      date: null,
      repetitions,
      info: {
        date: "",
        person_id: null,
        repetition: "0",
      },
      selectedCat: null,
      repetition_ns,
      month: new Date(),
      year: new Date(),
      showAccount: true,
      amount: "",
      lan: this.$i18n.locale == "de" ? de : it,
      id: "",
    };
  },
  components: {
    Datepicker,
    XIcon,
    TrashIcon,
  },
  computed: {
    accountList() {
      return this.$store.state.accounts.accountList.data || [];
    },
    persons() {
      let list = [{ name: "together", id: null }];
      this.$store.state.User.persons.data.forEach((person) => {
        list.push({
          name: person.name,
          id: person.id,
        });
      });
      return list;
    },
    categories() {
      let list = [];
      this.$store.state.User.categories.forEach(
        (m) => (list = list.concat(m.children))
      );

      return list;
    },
    categoriesTitles() {
      let list = [{ name: "all", id: null }];
      this.$store.state.User.categories.forEach((cat) => {
        list.push({
          name: cat.name,
          id: cat.id,
        });
      });
      return list;
    },
  },
  watch: {
    selectedCat: {
      handler: function (newVal) {
        if (newVal)
          this.$store.dispatch("User/GetCategory", {
            category_type_id: newVal,
          });
        if (this.selectedCat == 4) {
          this.showAccount = false;
        } else {
          this.showAccount = true;
        }
      },
    },
    month() {
      let month = (this.month.getMonth() + 1).toString().padStart(2, "0");
      this.info.date = `${this.year.getFullYear()}-${month}-01`;
      // this.$store.commit("setMainDate", this.month);
    },
    year() {
      let month = (this.month.getMonth() + 1).toString().padStart(2, "0");
      this.info.date = `${this.year.getFullYear()}-${month}-01`;
      // this.$store.commit("setMainDate", this.year);
    },
  },
  methods: {
    save() {
      if (this.info.date == "") {
        this.info.date = `${new Date().getFullYear()}-${
          new Date().getMonth() + 1
        }-01`;
      }
      const currentDate = {
        start: `${this.year.getFullYear()}-01-01`,
        end: `${this.year.getFullYear()}-12-31`,
      };
      if (this.info.repetition == 1) {
        [this.info.repetition_f, this.info.repetition_n] = [null, null];
      }
      if (this.mode == "new") {
        this.$store
          .dispatch("planning/RegisterPlan", { data: this.info, currentDate })
          .then((res) => {
            if (res.type == "success") this.$emit("close");
          });
        return;
      }
      this.$store
        .dispatch("planning/UpdatePlan", { info: this.info, currentDate })
        .then((res) => {
          if (res.type == "success") this.$emit("close");
        });
    },
    changeDate(e) {
      this.$store.commit("movement/setMovementDate", {
        type: "newDate",
        date: e.date,
      });

      this.info.date = `${this.year}-${this.month}-${this.day}`;
    },
    convertEntry() {
      if (this.amount == "") {
        this.amount = this.data.amount.toString();
      }
      String.prototype.replaceAllLocal = function (search, replacement) {
        var target = this;
        return target.split(search).join(replacement);
      };
      this.amount = this.amount.replaceAllLocal(".", "");
      this.amount = this.amount.replaceAllLocal(",", ".");
      this.amount = parseFloat(this.amount).toLocaleString("de-DE");
      this.info.amount = this.amount.replaceAllLocal(".", "");
      this.info.amount = this.info.amount.replaceAllLocal(",", ".");
      this.info.amount = Math.abs(this.info.amount);
    },
    deletePlan() {
      this.$store
        .dispatch("planning/DeletePlan", {
          id: this.id,
          currentDate: {
            start: `${this.year.getFullYear()}-01-01`,
            end: `${this.year.getFullYear()}-12-31`,
          },
        })
        .then(() => {
          this.$emit("close");
        });
    },
  },
  mounted() {
    // console.log("planModal");
    this.month = this.$store.state.mainDate;
    this.year = this.$store.state.mainDate;

    if (this.mode == "update") {
      this.id = this.data.id;
      this.month = new Date(this.data.date);
      this.year = new Date(this.data.date);
      this.info = this.data;
      this.info.amount = this.data.amount;
      this.info.person_id = this.data.person ? this.data.person.id : null;
      this.info.category_id = this.data.category ? this.data.category.id : null;
      this.info.account_id = this.info.account ? this.info.account.id : null;

      // if (this.data.amount.toString().includes(".")) {
      //   this.amount = this.data.amount.toString().replace(".", ",");
      // } else this.amount = this.data.amount;
      this.amount = this.data.amount.toLocaleString("de-DE");
    }
  },
};
</script>

<style scoped>
optgroup {
  background-color: #c026d3;
  color: #fff;
}
option {
  background-color: #fff;
  color: #47515f !important;
}
</style>
