<template>
  <form
    class="relative"
    @submit.prevent="info.save_id ? checkClearSaves() : save()"
  >
    <div>
      <div class="mx-4 p-4 text-center">
        <span class="text-3xl text-white">{{
          mode !== "new" ? $t("Update Movement") : $t("New Movement")
        }}</span>
      </div>
    </div>

    <div class="input">
      <div class="block relative w-full mb-2">
        <select
          placeholder="Pick a state..."
          v-model="selectedCat"
          class="
            w-full
            mb-2
            p-1
            bg-white
            border border-gray-400
            hover:border-gray-500
            px-4
            py-2
            pr-8
            rounded
            shadow
            leading-tight
            focus:outline-none focus:shadow-outline
          "
          required
        >
          <option
            v-for="tab in categoriesTitles"
            :key="tab.name"
            :value="tab.id"
            :selected="categoriesTitles[0].id"
          >
            {{ $t(tab.name) }}
          </option>
        </select>
      </div>
      <div class="block relative w-full mb-2">
        <select
          id="select-state"
          placeholder="Pick a state..."
          v-model="info.category_id"
          class="
            w-full
            mb-2
            p-1
            bg-white
            border border-gray-400
            hover:border-gray-500
            px-4
            py-2
            pr-8
            rounded
            shadow
            leading-tight
            focus:outline-none focus:shadow-outline
          "
          required
        >
          <optgroup
            v-for="(cat, i) in categories"
            :key="i"
            :label="$t(cat.name)"
          >
            <option
              v-for="(item, i) in cat.children.filter((cat) => cat.active)"
              :key="i"
              :value="item.id"
            >
              {{ $t(item.name) }}
            </option>
          </optgroup>
        </select>
      </div>

      <div class="flex flex-col mb-2">
        <span class="text-gray-700">{{ $t("Date") }}</span>
        <div class="flex">
          <datepicker
            class="
              modal-cs-width
              text-secondary
              bg-white
              rounded-md
              border border-gray-400
              hover:border-gray-500
              px-4
              py-2
              pr-8
              shadow
              leading-tight
              focus:outline-none focus:shadow-outline
            "
            :inputFormat="'dd-MM-yyyy'"
            v-model="date"
            :locale="lan"
          >
          </datepicker>
        </div>
      </div>
      <label class="text-gray-700">{{ $t("Amount") }}</label>
      <div class="input">
        <input
          type="text"
          name="betrag"
          tabindex="30"
          required
          v-model="amount"
          @change="convertEntry"
          class="
            w-full
            mb-2
            p-1
            bg-white
            border border-gray-400
            hover:border-gray-500
            px-4
            py-2
            pr-8
            rounded
            shadow
            leading-tight
            focus:outline-none focus:shadow-outline
          "
        />
      </div>
      <div v-if="selectedCat == 2 || selectedCat == 1" class="input">
        <div v-if="savesList.length" class="block relative w-full mb-2">
          <span class="text-gray-700">{{ $t("Withdraw from reserve") }}</span>
          <select
            v-model="info.save_id"
            class="
              w-full
              mb-2
              p-1
              bg-white
              border border-gray-400
              hover:border-gray-500
              px-4
              py-2
              pr-8
              rounded
              shadow
              leading-tight
              focus:outline-none focus:shadow-outline
            "
          >
            <option :value="null">-</option>
            <option v-for="(item, i) in savesList" :key="i" :value="item.id">
              {{ item.name }}
              {{
                mode == "update"
                  ? item.id == info.save_id
                    ? `(${(
                        parseFloat(item.total) + parseFloat(data.amount)
                      ).toLocaleString("de-DE")}€)`
                    : `(${parseFloat(item.total).toLocaleString("de-DE")}€)`
                  : `(${parseFloat(item.total).toLocaleString("de-DE")}€)`
              }}
            </option>
          </select>
        </div>
      </div>

      <div class="input">
        <div class="block relative w-full mb-2">
          <span class="text-gray-700">{{ $t("Person") }}</span>
          <select
            v-model="info.person_id"
            class="
              w-full
              mb-2
              p-1
              bg-white
              border border-gray-400
              hover:border-gray-500
              px-4
              py-2
              pr-8
              rounded
              shadow
              leading-tight
              focus:outline-none focus:shadow-outline
            "
          >
            <option v-for="(item, i) in persons" :key="i" :value="item.id">
              {{ item.name == "together" ? $t("together") : item.name }}
            </option>
          </select>
        </div>
      </div>
      <div v-if="showAccount" class="input">
        <div class="block relative w-full mb-2">
          <span class="text-gray-700">{{ $t("Account") }}</span>
          <select
            v-model="info.account_id"
            class="
              w-full
              mb-2
              p-1
              bg-white
              border border-gray-400
              hover:border-gray-500
              px-4
              py-2
              pr-8
              rounded
              shadow
              leading-tight
              focus:outline-none focus:shadow-outline
            "
          >
            <option v-for="(item, i) in accountList" :key="i" :value="item.id">
              {{ $t(item.name) }}
            </option>
          </select>
        </div>
      </div>

      <div class="input">
        <span class="text-gray-700">{{ $t("comment") }}</span>
        <textarea
          v-model="info.comment"
          class="
            block
            relative
            w-full
            mb-2
            p-1
            appearance-none
            bg-white
            border border-gray-400
            hover:border-gray-500
            px-4
            py-2
            pr-8
            rounded
            shadow
            leading-tight
            focus:outline-none focus:shadow-outline
          "
          rows="10"
        ></textarea>
      </div>
      <div>
        <button
          type="submit"
          class="
            w-full
            text-secondary
            flex
            justify-center
            py-2
            px-4
            border border-transparent
            rounded-md
            shadow-sm
            text-sm
            font-medium
            bg-white
            hover:bg-secondary hover:text-white
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-secondary
          "
        >
          {{ $t("Save") }}
        </button>
      </div>
      <div v-if="mode == 'update'">
        <button
          @click.prevent="deleteMovement()"
          class="
            mt-5
            w-full
            bg-red-500
            flex
            justify-center
            py-2
            px-4
            border border-transparent
            rounded-md
            shadow-sm
            text-sm
            font-medium
          "
        >
          <TrashIcon class="h-6 w-6 text-white" />
        </button>
      </div>
    </div>
    <div
      v-if="clearSave"
      class="
        fixed
        px-6
        rounded-md
        top-0
        right-0
        w-full
        h-full
        z-10
        inset-0
        overflow-y-auto
        bg-primary bg-opacity-50
      "
    >
      <div class="flex flex-col justify-center items-center w-full h-full z-10">
        <div
          class="
            flex flex-col
            bg-secondary
            h-24
            rounded-md
            w-full
            p-3
            z-20
            justify-between
            text-white
          "
        >
          <p class="text-center">
            {{ $t("Amount less than reserve: delete reserve?") }}
          </p>
          <div class="flex items-center justify-between">
            <button
              @click="closeClear(true)"
              class="flex items-center bg-white text-secondary px-3 rounded-md"
            >
              {{ $t("yes") }}
            </button>
            <button
              @click="closeClear(false)"
              class="bg-white text-secondary px-3 rounded-md"
            >
              {{ $t("no") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <button @click="$emit('close')" class="absolute top-0 right-0">
      <XIcon class="h-5 w-5 text-white" />
    </button>
  </form>
</template>

<script>
const repetitions = [
  { name: "no", value: "1" },
  { name: "monthly", value: "2" },
  { name: "yearly", value: "3" },
];
import Datepicker from "../../node_modules/vue3-datepicker";
import { de, it } from "date-fns/locale";
import { XIcon, TrashIcon } from "@heroicons/vue/outline";

export default {
  props: ["data", "mode"],
  data() {
    return {
      date: new Date(),
      repetitions,
      info: {
        date: "",
        type: "B",
        person_id: null,
        category_id: "",
      },
      selectedCat: null,
      month: new Date(),
      year: new Date(),
      amount: "",
      showAccount: true,
      clearSave: false,
      savesList: [],
      lan: this.$i18n.locale == "de" ? de : it,
      id: "",
    };
  },
  components: { Datepicker, XIcon, TrashIcon },
  computed: {
    accountList() {
      return this.$store.state.accounts.accountList.data || [];
    },

    persons() {
      let list = [{ name: "together", id: null }];
      this.$store.state.User.persons.data.forEach((person) => {
        list.push({
          name: person.name,
          id: person.id,
        });
      });
      return list;
    },
    categories() {
      let list = [];
      this.$store.state.User.categories.forEach(
        (m) => (list = list.concat(m.children))
      );
      return list;
    },
    categoriesTitles() {
      let list = [{ name: "all", id: null }];
      this.$store.state.User.categories.forEach((cat) => {
        list.push({
          name: cat.name,
          id: cat.id,
        });
      });
      return list;
    },
    // savesList() {
    //   return this.$store.state.movement.savesList;
    // },
  },
  watch: {
    selectedCat: {
      handler: function (newVal) {
        if (newVal)
          this.$store.dispatch("User/GetCategory", {
            category_type_id: newVal == "all" ? null : newVal,
          });
        if (this.selectedCat == 4) {
          this.showAccount = false;
        } else {
          this.showAccount = true;
        }
      },
    },
    date() {
      this.info.date = `${this.date.getFullYear()}-${(this.date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${this.date.getDate().toString().padStart(2, "0")}`;
    },
  },
  methods: {
    closeClear(status) {
      this.clearSave = false;
      this.info.clear_save = status;
      this.save();
    },
    checkClearSaves() {
      if (this.info.save_id == "null") {
        this.save();
      } else {
        const selectedSave = this.savesList.find(
          (i) => i.id == this.info.save_id
        );
        if (parseFloat(this.info.amount) <= parseFloat(selectedSave.total)) {
          this.clearSave = true;
        } else {
          this.info.clear_save = true;
          this.save();
        }
      }
    },
    save() {
      if (this.info.repetition == 1) {
        [this.info.repetition_f, this.info.repetition_n] = [null, null];
        this.info.date = this.info.date.toISOString().split("T")[0];
      }
      if (this.mode == "new") {
        if (this.info.date == "") {
          this.info.date = `${this.date.getFullYear()}-${(
            this.date.getMonth() + 1
          )
            .toString()
            .padStart(2, "0")}-${this.date
            .getDate()
            .toString()
            .padStart(2, "0")}`;
        }
        this.$store
          .dispatch("movement/RegisterMovement", this.info)
          .then((res) => {
            if (res.type == "success") this.$emit("close");
          });
        return;
      }

      this.$store.dispatch("movement/UpdateMovement", this.info).then((res) => {
        if (res.type == "success") this.$emit("close");
      });
    },
    convertEntry() {
      if (this.amount == "") {
        this.amount = this.data.amount.toString();
      }
      String.prototype.replaceAllLocal = function (search, replacement) {
        var target = this;
        return target.split(search).join(replacement);
      };
      this.amount = this.amount.replaceAllLocal(".", "");
      this.amount = this.amount.replaceAllLocal(",", ".");
      this.amount = parseFloat(this.amount).toLocaleString("de-DE");
      this.info.amount = this.amount.replaceAllLocal(".", "");
      this.info.amount = this.info.amount.replaceAllLocal(",", ".");
      this.info.amount = Math.abs(this.info.amount);
    },
    endMonthDay() {
      const endDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth() + 1,
        0
      );
      return endDate.getDate();
    },
    startDate() {
      return `${this.date.getFullYear()}-${(this.date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-01`;
    },
    endDate() {
      return `${this.date.getFullYear()}-${(this.date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${this.endMonthDay()}`;
    },
    getValidSavesList(id) {
      this.$store
        .dispatch("movement/getValidSavesList", {
          start: this.startDate(),
          end: this.endDate(),
          movement_id: id ? id : null,
        })
        .then((res) => {
          this.savesList = res;
          // console.log(this.savesList);
          // console.log(
          //   this.savesList.findIndex((i) => i.id !== this.data.save_id)
          // );
          if (this.data.save_category) {
            if (this.savesList.findIndex((i) => i.id == this.data.save_id)) {
              this.savesList.push({
                name: this.data.save_category
                  ? this.data.save_category.name
                  : "",
                id: this.data.save_category.is,
                total: this.amount,
              });
            }
          }
        });
    },
    deleteMovement() {
      this.$store.dispatch("movement/DeleteMovement", this.id).then(() => {
        this.$emit("fetchData");
        this.$emit("close");
      });
    },
  },
  mounted() {
    // console.log("movementMOdal");
    // console.log(this.data);
    this.date = this.$store.state.mainDate;
    this.id = this.data.id;
    if (this.mode == "update") {
      this.getValidSavesList(this.data.id);
      this.month = new Date(this.data.date);
      this.year = new Date(this.data.date);
      this.date = new Date(this.data.date);
      this.info = this.data;
      // this.amount = this.data.amount;
      // this.info.amount = this.data.amount;
      this.info.person_id = this.data.person ? this.data.person.id : null;
      this.info.category_id = this.data.category ? this.data.category.id : null;
      this.info.account_id = this.info.account_id
        ? this.info.account_id.id
        : null;
      if (this.data.category) {
        this.data.category.category_type_id == 4
          ? (this.showAccount = false)
          : (this.showAccount = true);
      }
      // if (this.data.amount.toString().includes(".")) {
      //   this.amount = this.data.amount.toString().replace(".", ",");
      // } else this.amount = this.data.amount;
      this.amount = parseFloat(this.data.amount).toLocaleString("de-DE");
      this.selectedCat = this.data.category.category_type_id;
      this.info.save_id = this.data.save_id ? this.data.save_id : null;
      // if(this.data.save_id )
      // if(this.savesList.findIndex(i=> i.id !== this.data.save_id)){
      //   console.log('hi');
      // }
    } else this.getValidSavesList();
  },
};
</script>

<style>
optgroup {
  background-color: #963d97;
  color: #fff;
}
option {
  background-color: #fff;
  color: #47515f !important;
}
</style>
