<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      static
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="$emit('close')"
      :open="open"
    >
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="
              inline-block
              align-bottom
              bg-primary
              rounded-lg
              px-4
              pt-5
              pb-4
              text-left
              shadow-xl
              transform
              transition-all
              sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6
            "
          >
            <component
              :is="component"
              @close="$emit('close')"
              @fetchData="$emit('fetchData')"
              :data="data"
              :parentId="parentId"
              :mode="mode"
            ></component>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
import PersonModal from "@/components/PersonModal.vue";
import CategoryModal from "@/components/CategoryModal.vue";
import PlanningModal from "@/components/PlanningModal.vue";
import MovementModal from "@/components/MovementModal.vue";
import AccountModal from "@/components/AccountModal.vue";
import effectiveModal from "@/components/effectiveModal.vue";
import transferModal from "@/components/transferModal.vue";
import MovementTransferModal from "@/components/MovementTransferModal.vue";
import SingleEffectiveModal from "@/components/SingleEffectiveModal.vue";

import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

export default {
  props: ["open", "type", "data", "parentId", "mode"],
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    PersonModal,
    CategoryModal,
    PlanningModal,
    MovementModal,
    AccountModal,
    effectiveModal,
    transferModal,
    MovementTransferModal,
    SingleEffectiveModal,
  },
  data() {
    return {
      component: null,
    };
  },
  watch: {
    type: {
      handler: function (newVal) {
        this.component = newVal;
      },
      setImmediate: true,
    },
  },
};
</script>
